<template>
    <div class="box" id="ygh-content-box">
        <div class="big">
            <div class="box-btns">
                <a-button
                    :type="btck == i ? 'primary' : ''"
                    icon="file-sync"
                    class="btns"
                    v-for="(o, i) in btna"
                    :key="i"
                    @click="change_status(i)"
                    >{{ o }}</a-button
                >
            </div>

            <div>
                <a-card :bordered="false">
                    <s-table
                        ref="table"
                        :columns="columns"
                        :data="loadData"
                        :alert="false"
                        :rowKey="(record) => record.id"
                    >
                        <template #operator>
                            <div style="display: flex; margin: 15px 0 0 0">
                                <div class="flex" style="margin: 0; width: 100%; justify-content: space-between">
                                    <div>
                                        <a-input-search
                                            placeholder="请输入关键字"
                                            @search="onSearch"
                                            class="search"
                                            v-model="queryParam.name"
                                        >
                                            <a-button slot="enterButton" icon="search"> </a-button>
                                        </a-input-search>
                                    </div>

                                    <!-- <div v-if="btck == 0"><a-button type="primary" @click="todoApproval"> 发起审批 </a-button></div> -->
                                </div>
                                <!-- <a-button type="primary" @click="showModel"> 发起审批 </a-button> -->
                            </div>
                        </template>
                        <span slot="draftId" slot-scope="text, record">
                            <div v-for="(o, i) in record.draftId" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData type="userName" :openid="o.userId" :corpid="o.corpId"></OpenData>
                                </a-tag>
                            </div>
                            <!-- <OpenData type="userName" :openid="record.draftId" :corpid="record.corpId"></OpenData> -->
                        </span>
                        <span slot="documentType" slot-scope="text">
                            <span v-if="text == +1">收文登记</span>
                            <span v-else-if="text == +2">快速发文</span>
                            <span v-else-if="text == +3">拟稿发文</span>
                        </span>
                        <span slot="fileType" slot-scope="text">
                            {{ Filter(flow_file_type, text) }}
                        </span>
                        <span slot="acceptTime" slot-scope="text">
                            {{ text && text.substr(0, 10) }}
                        </span>
                        <span slot="urgencyDegree" slot-scope="text" :style="flowUrgencyDegree(text)">
                            {{ Filter(flow_urgency_degree, text) }}
                        </span>
                        <span slot="secrecyDegree" slot-scope="text" :style="flowSecrecyDegree(text)">
                            {{ Filter(flow_secrecy_degree, text) }}
                        </span>
                        <span slot="userId" slot-scope="text, record">
                            <OpenData type="userName" :openid="record.userId" :corpid="record.corpId"></OpenData>
                        </span>
                        <span slot="approvalStatus" slot-scope="text" :class="text != 2 ? 'fred' : ''">
                            {{ Filter(flow_approval_status, text) }}
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <!-- <a class="bts" v-if="record.approvalStatus == 0" @click="edit(record)">修改</a> -->
                            <a
                                class="bts"
                                v-if="record.approvalStatus == 1 && record.approvalFlag !== 1"
                                @click="senpi(record)"
                                >审批</a
                            >
                            <!-- <a class="bts" v-if="record.approvalStatus == 2">加签</a> -->
                            <a v-else class="bts" @click="see(record)">查看</a>
                        </span>
                    </s-table>
                </a-card>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line camelcase
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { documentBillAuditedPage, documentBillAuditPage } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import { getEndColumns, satrtColumns } from './config'

export default {
    name: 'list',
    mixins: [stateColor],
    components: {
        STable,
        OpenData,
    },
    computed: {
        columns() {
            let arr = []
            const endColumns = getEndColumns(this.flow_approval_status, this.btck === 0)
            arr = [...satrtColumns, ...endColumns]
            if (this.btck === 1)
                arr = [
                    ...satrtColumns,
                    { title: '审批人', dataIndex: 'userId', scopedSlots: { customRender: 'userId' } },
                    ...endColumns,
                ]
            return arr
        },
    },
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['全部', '待我审核', '我已审核'], // '登记列表',
            btck: 0,
            // 查询参数
            queryParam: { approvalTab: 0, documentType: 1 },
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                if (this.btck === 3) {
                    const obj = Object.assign(parameter, this.queryParam)
                    return documentBillAuditedPage(obj)
                        .then((res) => {
                            const data = { ...res.data }
                            data.rows = data.rows.map((ele) => {
                                ele.draftId = formatDraftId(ele.draftId)
                                return ele
                            })
                            return data
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                const { approvalStatus } = parameter
                let params = { ...parameter }
                if (approvalStatus && approvalStatus.length > 0)
                    params = { ...parameter, approvalStatus: approvalStatus[0] }
                return documentBillAuditPage(Object.assign(params, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66, res)
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    watch: {
        '$route.query.index': {
            handler() {
                console.log(this.$route.query.index, '$route.query.index')
                this.change_status(Number(this.$route.query.index) || 0)
            },
            immediate: true,
        },
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool'], 66)
    },
    methods: {
        addApproval(record) {
            this.$refs.stepsForm.open({ type: 'approval', id: record.id })
        },
        Filter(arr, s) {
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 获取字典数据
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
            })
        },
        todoApproval() {
            console.log(this.selectedRowKeys)
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        change_status(i) {
            this.btck = i
            this.queryParam.approvalTab = i
            this.$refs.table && this.$refs.table.refresh(true)
        },
        edit(record) {
            this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        senpi(record) {
            const { approvalId, nodeId, id, documentType } = record
            this.noticeUpdate(id)
            this.$router.push({
                path: '/fileStream_Approval_Manage',
                query: { id, approvalId, nodeId, type: documentType },
            })
        },
        see({ id, documentType }) {
            this.$router.push({ path: '/fileStream_Approval_process', query: { id, type: documentType } })
        },
        // 去掉未通知提示
        noticeUpdate(id) {
            this.$store.dispatch('msg/noticeRefreshUpdate', { id }).then((res) => {
                if (res.code === 200) {
                    this.$store.dispatch('msg/unReadNums')
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.box {
    width: 1280px;
    margin: auto !important;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
    padding: 33px 39px;

    .big {
        button {
            font-size: 16px;
        }
    }

    .flex {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px 0;
    }

    .search {
        width: 300px;
        border-radius: 4px;
    }
}

::v-deep .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
}

::v-deep .ant-card-body {
    padding: 0;
}

.btns {
    margin-right: 17px;
}

.fred {
    color: #f56c6c;
}

.bts {
    margin-right: 10px;
}

.flex0 {
    display: flex;
    align-items: center;
}

.my-item {
    display: inline-block;

    .tags {
        margin-bottom: 1px;
    }
}
</style>
